<template>
    <div id="MarketingTools_SharePromotion">

        <el-button type="primary" size="small" @click="isShow_poster = true" style="margin-bottom:20px">
            新增分享海报
        </el-button>
        <el-table :data="tableData" style="width: 100%" :max-height="maxHeight_Table"
            :header-cell-style="{ background: '#F8FBFF' }">
            <el-table-column prop="title" label="活动名称" align="center"></el-table-column>
            <el-table-column prop="rate" label="分佣比例" align="center">
                <template slot-scope="scope">
                    <div>
                        <el-input v-model="scope.row.rate" placeholder=" " size="small" style="width: 80px;"></el-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="name" label="操作" align="right" width="120">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="新增海报" :visible.sync="isShow_poster" width="440px">
            <div class="Addhotel_dialog ">
                <div class="Addhotel_dialog_top ">
                    <div class="Addhotel_dialog_top_left ">
                        <div class="Addhotel_dialog_top_left_title">海报样式</div>
                        <div class="Addhotel_dialog_top_left_content">
                            <div class="Addhotel_dialog_top_right_up"><i class="el-icon-arrow-up"></i></div>
                            <div class="Addhotel_dialog_top_right_down"><i class="el-icon-arrow-down"></i></div>
                            <div class="Addhotel_dialog_top_left_content_list">
                                <div class="Addhotel_dialog_top_left_content_list_item" v-for="item in ImgList"
                                    :key="item.id" @click="changeImg(item)">
                                    <img :src="item.url">
                                    <div :class="{ 'triangleBox': true, 'Box': item.id != selectImgID }">
                                        <div class="triangle_top"></div>
                                        <div class="triangle"></div>
                                        <div class="triangle1"></div>
                                        <div class="triangle2"></div>
                                        <div class="triangle_bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="Addhotel_dialog_top_right ">
                        <!-- 海报部分内容 -->
                        <div>
                            <div class="Addhotel_dialog_top_right_top ">
                                <img :src="selectImg" alt="">
                            </div>
                            <div class="Addhotel_dialog_top_right_bottom ">
                                <div class="Addhotel_dialog_top_right_bottom_left">
                                    <img :src="selectImg" alt="">
                                    <span>观视界国际旅行社</span>
                                </div>
                                <div class="Addhotel_dialog_top_right_bottom_right">
                                    <img :src="selectImg" alt="">
                                </div>
                            </div>
                        </div>
                        <!--END 海报部分内容 -->

                        <div class="Addhotel_dialog_top_right_btn ">
                            <el-button type="primary" size="small" @click="isShow_poster = true" style="width: 140px;">
                                生成海报 </el-button>
                        </div>
                    </div>
                </div>
                <div class="Addhotel_dialog_bottom ">
                    <div class="Addhotel_dialog_bottom_title ">分享说明 </div>
                    <p>1.分享海报后，客人可通过识别二维码关注小程序成为您的会员
                        后续此会员在小程序所有消费都与您关联
                    </p>
                </div>

                <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
                <button @click="generateImage">合成图片</button>

            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            canvasWidth: 400,
            canvasHeight: 300,
            boxContent: '这是盒子里的内容',
            imageUrl: 'https://example.com/image.jpg',

            tableData: [{}],
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },
            maxHeight_Table: '800',
            isShow_poster: false,
            ImgList: [
                {
                    id: 1,
                    url: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/27/xiaoqiao'
                },
                {
                    id: 2,
                    url: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/27/xiaoqiao'
                },
                {
                    id: 3,
                    url: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/27/xiaoqiao'
                },
                {
                    id: 4,
                    url: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/27/xiaoqiao'
                },
                {
                    id: 5,
                    url: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/27/xiaoqiao'
                },
                {
                    id: 6,
                    url: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/27/xiaoqiao'
                }
            ],
            selectImgID: 0,
            selectImg: '',

        }
    },
    created() {
        this.getList()
        this.selectImg = this.ImgList[0].url
        this.selectImgID = this.ImgList[0].id
    },
    mounted() { },
    methods: {
        generateImage() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');

            // 绘制盒子
            ctx.fillStyle = 'lightblue';
            ctx.fillRect(50, 50, 300, 200);

            // 绘制文本
            ctx.fillStyle = 'black';
            ctx.font = '20px Arial';
            ctx.fillText(this.boxContent, 100, 150);

            // 绘制图片
            const image = new Image();
            console.log('image',image);
            
            image.onload = () => {
                ctx.drawImage(image, 150, 100, 100, 100);

                // 保存合成后的图片
                const dataURL = canvas.toDataURL('image/png');

                // 可以将dataURL发送到服务器保存，或者在前端显示
                console.log(123123123);
                console.log('dataURL',dataURL);
            };
            image.src = this.imageUrl;
            console.log(' this.imageUrl', this.imageUrl);
          
        },
        // 获取分享拉新列表
        getList() {
            this.my_request('admin/share').then(res => {
                console.log('获取分享拉新列表结果', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData = data
                    // this.pageNation_data.total = data.total
                }
            })
        },
        changeImg(item) {
            this.selectImgID = item.id
            this.selectImg = item.url
        },

    }
}
</script>


<style lang="scss" scoped>
/* 隐藏滚动条 */
.Addhotel_dialog_top_left_content_list::-webkit-scrollbar {
    width: 0em;
    /* 设置滚动条宽度为0em */
}

#MarketingTools_SharePromotion {
    .Addhotel_dialog {
        .Addhotel_dialog_bottom {
            width: 340px;
            padding: 0 50px;
            margin-top: 20px;
            line-height: 20px;

            .Addhotel_dialog_bottom_title {
                font-weight: bold;
            }

            p {
                margin: 0;
                font-size: 14px;
                color: #909399;
            }
        }

        .Addhotel_dialog_top {
            background-color: #F8F7F7;
            width: 390px;
            height: 510px;
            padding-left: 50px;
            display: flex;

            .Addhotel_dialog_top_right {
                padding-top: 50px;
                width: 216px;
                height: 510px;

                .Addhotel_dialog_top_right_top {
                    width: 216px;
                    height: 320px;

                    img {
                        width: 216px;
                        height: 320px;
                    }
                }

                .Addhotel_dialog_top_right_bottom {
                    height: 64px;
                    background-color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .Addhotel_dialog_top_right_bottom_left {
                        margin-left: 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            font-size: 13px;
                            font-weight: bold;
                            margin-left: 5px
                        }

                        img {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                        }
                    }

                    .Addhotel_dialog_top_right_bottom_right {
                        margin-right: 8px;

                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }


                .Addhotel_dialog_top_right_btn {
                    width: 216px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 20px 0;
                }
            }

            .Addhotel_dialog_top_left {
                margin-right: 20px;

                .Addhotel_dialog_top_left_title {
                    height: 50px;
                    line-height: 50px;
                }

                .Addhotel_dialog_top_left_content {
                    height: 344px;
                    width: 95px;
                    position: relative;
                    padding: 20px 0;

                    .Addhotel_dialog_top_left_content_list {
                        overflow: auto;
                        height: 344px;
                    }

                    .Addhotel_dialog_top_left_content_list_item {
                        position: relative;
                        display: inline-block;
                        width: 94px;
                        height: 68px;
                        margin-bottom: 5px;

                        img {
                            width: 94px;
                            height: 68px;
                        }

                        .Box {
                            width: 5px;
                            height: 68px;
                            background-color: #F8F7F7;
                            position: absolute;
                            right: 0;
                            top: 0;

                        }

                        .triangleBox {
                            position: absolute;
                            width: 5px;
                            height: 68px;
                            right: 0;
                            top: 0;
                            overflow: hidden;

                            .triangle {
                                position: absolute;
                                top: 50%;
                                right: 0;
                                width: 0;
                                height: 0;
                                // background-color: #A9A9A9;
                                border-top: 5px solid transparent;
                                /* 三角形高度 */
                                border-left: 5px solid transparent;
                                /* 三角形宽度和颜色 */
                                border-bottom: 5px solid transparent;
                                /* 三角形高度 */
                                transform: translateY(-50%);
                            }

                            .triangle1 {
                                position: absolute;
                                top: 46%;
                                right: 0;
                                width: 0;
                                height: 0;
                                // background-color: #A9A9A9;
                                border-top: 3px solid #F8F7F7;
                                /* 三角形高度 */
                                border-left: 3px solid transparent;
                                /* 三角形宽度和颜色 */
                                border-bottom: 3px solid transparent;
                                border-right: 3px solid #F8F7F7;
                                /* 三角形高度 */
                                transform: translateY(-50%);
                            }

                            .triangle2 {
                                position: absolute;
                                top: 55%;
                                right: 0;
                                width: 0;
                                height: 0;
                                // background-color: #A9A9A9;
                                border-top: 3px solid transparent;
                                /* 三角形高度 */
                                border-left: 3px solid transparent;
                                /* 三角形宽度和颜色 */
                                border-bottom: 3px solid #F8F7F7;
                                border-right: 3px solid #F8F7F7;
                                /* 三角形高度 */
                                transform: translateY(-50%);
                            }

                            .triangle_top {
                                position: absolute;
                                top: 0px;
                                right: 0;
                                width: 5px;
                                height: 29px;
                                background-color: #F8F7F7;
                            }

                            .triangle_bottom {
                                position: absolute;
                                bottom: 0px;
                                right: 0;
                                width: 5px;
                                height: 29px;
                                background-color: #F8F7F7;

                            }
                        }

                    }

                    .Addhotel_dialog_top_right_up,
                    .Addhotel_dialog_top_right_down {
                        width: 88px;
                        height: 16px;
                        background-color: #A9A9A9;
                        text-align: center;
                        position: absolute;
                        left: 0;

                        i {
                            color: #FFFFFF;
                        }
                    }

                    .Addhotel_dialog_top_right_up {
                        top: 0;
                    }

                    .Addhotel_dialog_top_right_down {
                        bottom: 0;
                    }
                }
            }
        }
    }

    /deep/.el-dialog__body {
        padding: 20px 0 !important;
    }
}
</style>